import { EnvironmentModel, EnvName } from '@pt/models';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.dev.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: EnvironmentModel = {
  shortName: 'ta',
  appName: 'PIN-UP Trainee Admin',
  production: false,
  useMocks: false,
  envName: EnvName.DEV,
  apiHost: (window as any).apiHost || 'https://api-pinup-team-trainee-dev.pin-up.team/api/v1/',
  wssHost: (window as any).wssHost || 'wss://api-pinup-team-dev.pin-up.team/api/v1/',
  lmsHost: (window as any).lmsHost || 'https://dev-trainee-lms.pin-up.team/',
  assetsHost: '',
  tokenPostfix: 'trainee-1',
  cookieDomain: 'pin-up.team',
  defaultLang: 'en',
  supportedUiLanguages: ['en', 'uk', 'ru'],
  theme: 'light',
  googleClientId: '888042350879-3aag02nkjk03r7qbocvi2cfk9ss5ppbb.apps.googleusercontent.com',
  slackTeamId: 'T01FZLAFN9Z',
  slackWorkspace: 'pin-up-global',
  allowedPermissions: [
    'Admin',
    'AdminUsers',
    'AdminUsersGroups',
    'AdminUsersStatuses',
    'AdminUsersLocations',
    'AdminUsers2FAForUsers',
    'AdminUserInfo',
    'AdminCourses',
    'AdminMessageHubLearningAndDevelopmentMessages',
  ],
  adminSlug: '',
  imagesOptimizationEnabled: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
